import React, {useEffect} from "react";
import "reactjs-popup/dist/index.css";
import { StyledPopup } from "./style";
import {
  ReclamationContactFormHeader,
  ReclamationContactFormTitle,
  ReclamationContactFormClose,
  ReclamationContactFormBody,
  ReclamationPopupP,
  ReclamationControlWrapper
} from "../../components/ReclamationContactForm/style";
import { Icon } from "../Icon";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import ReactHtmlParser from "react-html-parser";
import { showPopup } from "./slices";
import { useIntl } from "react-intl";
import Accordions from "../Accordions";
import {ButtonFull} from "../Form/Button";
import {clearReturnadoToken} from "../../store/root-slices";
import {useLocation, useHistory} from "react-router";
import {setLoggedOut} from "../../containers/Main/slices";
import {removeUrlParameter} from "../../utils/common";
import {setShopCodes} from "../../store/root-slices";
import {setShopCodes2} from "../../store/root-slices";
import {hideLoader, showLoader} from "../Loader/slices";


export const PopupWindow = ({
  content = "",
  title = "",
  onClose = null,
  closeOnDocumentClick,
  closeOnEscape,
  popupType='',
  ...rest
}) => {
  let onOpenEvent = () => {
    if (onClose) {
      let overlay = document.querySelector('[data-testid="overlay"]');
      let closeHandling = (e) => {
        if (e.key === "Escape") {
          onClose();
          hideStandaloneLoader()
        }
        document.removeEventListener("keyup", closeHandling, false);
      };
      overlay.addEventListener("click", (e) => {
        if (e.target.dataset.testid === "overlay") {
          onClose();
          document.removeEventListener("keyup", closeHandling, false);
          hideStandaloneLoader()
        }
      });
      document.addEventListener("keyup", closeHandling, false);
    }
  };
  let dispatch = useDispatch();
  const intl = useIntl()

  const { data } = useSelector((state) => state.popup);
  const location = useLocation()
  const history = useHistory()

  /**
   * handleWidgetErrorMessage {Function}
   * @description action handle error data fired from Widget through snippet.js
   * */
  let handleWidgetErrorMessage = (errorData) =>{
    if(isErrorNeedToBeShown(errorData)){
      dispatch(showPopup(errorData))
    }
  };

  /**
   * handleWidgetSuccessMessage {Function}
   * @description action handle success data fired from Widget through snippet.js
   * */
  let handleWidgetSuccessMessage = (successData) =>{
    //console.log(successData, 'success from standalone')
    //dispatch(showPopup(successData))
  };

  let addCodes = (codes) =>{
    dispatch(setShopCodes(codes))
  };

  let addCodes2 = (merchantMarketCode, codes) =>{
    let payload = { merchantMarketCode: merchantMarketCode, codes: codes }
    dispatch(setShopCodes2(payload))
  };

  /**
   * hideStandaloneLoader {Function}
   * @description action to hide standalone common loader
   * */
  let hideStandaloneLoader = () => {
    dispatch(hideLoader())
  }

  /**
   * showStandaloneLoader {Function}
   * @description action to show standalone common loader
   * */
  let showStandaloneLoader = () => {
    dispatch(showLoader())
  }

  useEffect(()=>{

    if(!window.returnadoHandlers) {

      window.returnadoHandlers = {};
      window.returnadoHandlers.handleWidgetErrorMessage = handleWidgetErrorMessage;
      window.returnadoHandlers.handleWidgetSuccessMessage = handleWidgetSuccessMessage;
      window.returnadoHandlers.addCodes = addCodes;
      window.returnadoHandlers.addCodes2 = addCodes2;
      window.returnadoHandlers.hideStandaloneLoader = hideStandaloneLoader;
      window.returnadoHandlers.showStandaloneLoader = showStandaloneLoader;

    } else {

      if(!window.returnadoHandlers.handleWidgetErrorMessage) {
        window.returnadoHandlers.handleWidgetErrorMessage = handleWidgetErrorMessage;
      }

      if(!window.returnadoHandlers.handleWidgetSuccessMessage) {
        window.returnadoHandlers.handleWidgetSuccessMessage = handleWidgetSuccessMessage;
      }

      if(!window.returnadoHandlers.addCodes) {
        window.returnadoHandlers.addCodes = addCodes;
      }

      if(!window.returnadoHandlers.hideStandaloneLoader) {
        window.returnadoHandlers.hideStandaloneLoader = hideStandaloneLoader;
      }

      if(!window.returnadoHandlers.showStandaloneLoader) {
        window.returnadoHandlers.showStandaloneLoader = showStandaloneLoader;
      }

    }

  }, [addCodes])   // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * isErrorNeedToBeShown { Function }
   * @description - define should error notification be shown or not.
   * @return { Boolean }
   * */
  let isErrorNeedToBeShown = (errorData) => {
    let isErrorNeedToBeShown = true;

    if(errorData && errorData.title !== "Unauthorized") {
      isErrorNeedToBeShown = true;
    }

    if(errorData && errorData.error && !errorData.error.message && errorData.error.headerStatus === 500) {
      isErrorNeedToBeShown = false;
    }

    if(errorData && errorData.error && errorData.error.code === 401 && errorData.error.error === "Unauthorized") {
      /* make standalone logout */
      removeUrlParameter(location, history, 'returnadoToken')
      dispatch(setLoggedOut());
      dispatch(clearReturnadoToken());

      isErrorNeedToBeShown = false;
    }

    if(errorData && errorData.error && errorData.error.headerStatus && errorData.error.headerStatus === 422) {
      if(errorData.error.message && errorData.error.message.includes("period expired")) {
        isErrorNeedToBeShown = false;
      }
      if(errorData.error.message && errorData.error.message.includes("item id:") && errorData.error.message.includes("already exist")) {
        isErrorNeedToBeShown = false;
      }
    }

    if(errorData && errorData.error && errorData.error.type && errorData.error.type === 'pendingItemPopup') {
      isErrorNeedToBeShown = false;
    }

    if(errorData && errorData.error && errorData.error.type && errorData.error.type === 'returnedItemPopup') {
      isErrorNeedToBeShown = false;
    }

    if(errorData && errorData.error && errorData.error.type && errorData.error.type === 'imageSizeError') {
      isErrorNeedToBeShown = false;
    }

    if(errorData && errorData.title === 'Something went wrong' && errorData.error) {
      if(errorData.error.message) {
        let messageArr = errorData.error.message.split('.');
        if(messageArr.length === 3 && messageArr[1].trim() === 'Action unavailable') {
          isErrorNeedToBeShown = false;
        }
      }
      if(errorData.content) {
        if(errorData.content === 'No shipping selector option chosen') {
          isErrorNeedToBeShown = false;
        }
      }
    }

    return isErrorNeedToBeShown;

  }

  let getPopupTitle = (data={}) => {
    let titleTxt = "Something went wrong";

    if(data && data.type) {
      switch (data.type) {
        case 'terms':
          return <FormattedMessage
              id='login.form.termsAndConditions.link'
              defaultMessage='Terms and Conditions'
          />
        case 'policy':
          return <FormattedMessage
              id='login.form.privacyPolicy.link'
              defaultMessage='Privacy Policy'
          />
        case 'inDevelopment':
          return 'Development process...'
        default:
          return ''
      }
    }

    if(!data || (data && data.message && data.message.includes('Customer for email ['))) {
      return <FormattedMessage
        id={'login.emailSent.header'}
        defaultMessage={'Link Sent To Your Email'}
      />
    }

    if(data.error && data.error.headerStatus == 404 ) {
      return <FormattedMessage
        id='login.loginFail.header'
        defaultMessage=''
      />
    }

    if(data && data.title) {
      titleTxt = data.title;
    }

    return titleTxt;
  };

  let getPopupContent = (data={}) => {
    let contentTxt = "Resource temporary unavailable, please, try again later";

    if(data && data.type) {
      switch (data.type) {
        case 'terms':
          return ReactHtmlParser(intl.formatMessage({
            id: 'login.termsAndConditions.text',
            defaultMessage: '',
          }))
        case 'policy':
          try {
            let element = document.createElement('div');
            element.innerHTML = data.data.text;
            let titleArr = Array.from(element.querySelectorAll('h2'));
            let textArr = Array.from(element.querySelectorAll('h2 + div'));
            let accordionList = [];

            titleArr.forEach((title, i)=>{
              accordionList.push({
                title: title.innerHTML,
                text: textArr[i].innerHTML
              })
            })
            return <Accordions list={accordionList} isOpenByDefault={data.cookiePolicyIndex} />
          } catch (e) {
            return <div>{ReactHtmlParser(data.data.text)}</div>
          }
        case 'inDevelopment':
          return 'This functionality is still in developing'
        default:
          return ''
      }
    }

    if(data && data.content) {
      contentTxt = data.content;
    }

    if(!data || (data && data.message && data.message.includes('Customer for email ['))) {
      return <FormattedMessage
          id={'login.emailSent.text'}
          defaultMessage={
            "<p>An e-mail with a link to view your orders has been sent to you, follow the link in the e-mail to start creating your return.</p><p>If you haven't received your e-mail within 3 minutes we couldn't match your e-mail to an order.</p>"
          }
          values={{
            p: (chunks) => <ReclamationPopupP>{chunks}</ReclamationPopupP>,
          }}
      />
    }

    return contentTxt;
  };

    /**
     * isButtonVisible {Function}
     * @param {Object}
     * @description - define do we need to show additional button
     * @return {Boolean}
     * */
    let isButtonVisible = (errorData) => {
        let isButtonVisible = false;

        if (errorData && errorData.message) {
            let {message=''} = errorData;
            if(message.includes("Customer for email") && message.includes("not found")) {
                isButtonVisible = true
            }
        }

        if(errorData && errorData.title) {
          if(errorData.title === 'No order could be found') {
            isButtonVisible = true
          }
        }

        if (!errorData) {
            isButtonVisible = true
        }

        return isButtonVisible
    }

  return (
    <StyledPopup
      {...rest}
      position={"bottom center"}
      closeOnDocumentClick={closeOnDocumentClick}
      closeOnEscape={closeOnEscape}
      nested
      modal
      onOpen={onOpenEvent}
    >
      {(close) => {
        const closeEvent = () => {
          if (onClose) {
            onClose();
          }
          close();
          hideStandaloneLoader()
        };
        /*const mutableContent = {
          ...content,
          props: {
            ...content.props,
            onClose: closeEvent,
          },
        };*/
        return (
          <>
            <ReclamationContactFormHeader>
              <ReclamationContactFormTitle className="model">
                <ReclamationContactFormClose onClick={closeEvent}>
                  <Icon component="closePopup" />
                </ReclamationContactFormClose>{" "}
                {getPopupTitle(data)}
              </ReclamationContactFormTitle>
            </ReclamationContactFormHeader>
            <ReclamationContactFormBody className={`col-md-12 col-sm-12 modal modal_${data?.type || popupType}`}>
              {getPopupContent(data)}
              {isButtonVisible(data) ? (
                  <ReclamationControlWrapper>
                    <ButtonFull
                        w100
                        onClick={() => {
                          closeEvent(data)
                        }}
                        dark
                        className='modalButton'
                    >
                      <FormattedMessage
                          id='gcFinal.dialog.ok.button'
                          defaultMessage='OK'
                      />
                    </ButtonFull>
                  </ReclamationControlWrapper>
              ) : (
                  ''
              )}
            </ReclamationContactFormBody>
          </>
        );
      }}
    </StyledPopup>
  );
};
